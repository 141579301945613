import { ref } from "vue";

export default () => {
  const sortBy = ref<string | null | undefined>(null);
  const orderBy = ref<string | null | undefined>(null);

  const setParamsByEvent = (
    event: {
      columnKey: string;
      order: string | boolean;
    },
    sortingTypes: Map<string, string>,
    sortingFields: Map<string, string>
  ) => {
    if (!event) {
      return;
    }
    sortBy.value = event.order
      ? sortingFields.get(event.columnKey as keyof typeof sortingFields)
      : null;
    orderBy.value = event.order
      ? sortingTypes.get(event.order as keyof typeof sortingTypes)
      : null;
  };
  return {
    sortBy,
    orderBy,
    setParamsByEvent,
  };
};
