export default () => {
  const onFilterTriggered = (payload: {
    collection: Set<string> | Map<string, string>;
    key: string;
    value?: string;
  }) => {
    const { key, value } = payload;
    const collection = value
      ? (payload.collection as Map<string, string>)
      : (payload.collection as Set<string>);
    if (collection.has(key)) {
      collection.delete(key);
    } else if (value) {
      (collection as Map<string, string>).set(key, value);
    } else {
      (collection as Set<string>).add(key);
    }
  };
  return {
    onFilterTriggered,
  };
};
